<template>
  <nav id="bottom-menu" class="bottom-menu-visible">
    <ul class="menu-list-container">
      <li>
        <router-link to="/" @click.native="useHomeButton()">
          <img class="home" :src="require('../assets/images/bottom_menu_icons/home.svg')" alt="">
        </router-link>
      </li>
      <li v-if="platform !== 'ios'">
        <router-link to="/skanuj">
          <img class="scanner" :src="require('../assets/images/bottom_menu_icons/barcode.svg')" alt="">
        </router-link>
      </li>
      <li v-else>
        <a href="javascript:void(0)"><img :src="require('../assets/images/bottom_menu_icons/barcode.svg')" alt=""></a>
      </li>
      <li>
        <router-link to="/wyszukiwanie">
          <img class="search" :src="require('../assets/images/bottom_menu_icons/loupe.svg')" alt="">
        </router-link>
      </li>t
    </ul>
  </nav>
</template>

<script>
import { scroller } from 'vue-scrollto/src/scrollTo'
import EventBus from '../eventBus'
export default {
  name: 'BottomMenu',
  data () {
    return {
      scroller: scroller(),
      platform: window.platform,
      homeButtonActive: false
    }
  },
  created () {
    EventBus.$on('feed-hidden', this.homeActive)
    EventBus.$on('feed-visible', this.homeInactive)
  },
  computed: {
    showFeed: {
      get () {
        return this.$store.state.userData.show_feed
      }
    }
  },
  methods: {
    homeActive () {
      this.homeButtonActive = true
    },
    homeInactive () {
      this.homeButtonActive = false
    },
    useHomeButton () {
      if (this.homeButtonActive === true && this.showFeed === true) {
        this.$parent.sideMenuOpen = false
        this.scroller('#feed', 500, { cancelable: false, onStart: function () { document.body.classList.add('no-scroll') }, onDone: function () { EventBus.$emit('show-feed', false) } })
      } else if (this.homeButtonActive === false && this.showFeed === false) {
        this.$parent.sideMenuOpen = false
        this.scroller('#feed', 500, { cancelable: false, onStart: function () { document.body.classList.add('no-scroll') }, onDone: function () { EventBus.$emit('reset-scroll', false) } })
      } else if (this.homeButtonActive === false && this.showFeed === true) {
        EventBus.$emit('go-to-first-card', false)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
