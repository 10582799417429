import axios from 'axios'

// export const api = axios.create({
//   baseURL: 'https://bookme.com.pl/api'
// })

// export const coverUrl = 'https://bookme.com.pl/img/books/'

// export const plainUrl = 'https://bookme.com.pl'

// export const bookfaceUrl = 'https://bookme.com.pl/img/bookface/'

export const api = axios.create({
  baseURL: 'https://bookme.dev.coreo.pl/api'
})

export const coverUrl = 'https://bookme.dev.coreo.pl/img/books/'

export const plainUrl = 'https://bookme.dev.coreo.pl'

export const bookfaceUrl = 'https://bookme.dev.coreo.pl/img/bookface/'

export function getToken () {
  return localStorage.getItem('token')
}

export function deleteToken () {
  return localStorage.removeItem('token')
}

export function saveToken (token) {
  localStorage.setItem('token', token)
}

export function getReopen () {
  return localStorage.getItem('isReopen')
}

export function getOnboardingFinished () {
  return localStorage.getItem('finished')
}

export function setOnboardingFinished (item) {
  return localStorage.setItem('finished', item)
}

export function deleteOnboardingFinished () {
  return localStorage.removeItem('finished')
}
