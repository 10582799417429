<template>
  <div class="top-menu-visible" :class="{ 'menu-opened': this.$parent.sideMenuOpen }">
    <a :class="{'hidden': profileIconVisible || ($route.name === 'UserProfile' && !showFeed)}"
      class="app-menu-back"
      @click="useBackButton"
      ><i class="fas fa-chevron-left"></i
    ></a>
    <div class="app-title sub-title">
      <span class="app-title-text mt-1">Bookme</span>
    </div>
    <router-link to="/powiadomienia">
      <a class="notifications" href="#">
        <span class="notification-amount" v-if="amount > 0"></span>
        <img :src="require('../assets/images/side_menu_icons/messages.svg')" alt="">
      </a>
    </router-link>
    <a class="app-menu-trigger" @click="toggleSideMenuOpen" :class="{'hidden': profileIconVisible}"></a>
    <a class="app-menu-profile" :style="{backgroundImage: 'url(' + (userImage ? plainUrl + userImage : require('../assets/avatar-placeholder.jpg')) + ')'}" :class="{'hidden': !profileIconVisible}" @click="goToProfile"></a>
    <div id="app-menu" :class="{'shadow': this.$parent.sideMenuOpen}">
      <div class="user-info">
        <div class="user-image" :style="{backgroundImage: 'url(' + (userImage ? plainUrl + userImage : require('../assets/avatar-placeholder.jpg')) + ')'}"></div>
        <div class="user-name">
          {{ fullname }}
        </div>
      </div>
      <ul>
        <li>
          <router-link to="/biblioteka">
            <img src="../assets/images/side_menu_icons/library.svg">
            <a
              >Moja biblioteka</a>
          </router-link>
        </li>
        <li>
          <img src="../assets/images/side_menu_icons/plans.svg">
          <a @click="checkPlan()">Plan czytelniczy</a>
        </li>
        <li>
          <router-link to="/recenzje">
            <img src="../assets/images/side_menu_icons/star.svg">
            <a>Recenzje</a>
          </router-link>
        </li>
        <li>
          <router-link to="/moje-konto">
            <img src="../assets/images/side_menu_icons/gear.svg">
            <a>Moje konto</a>
          </router-link>
        </li>
        <li>
          <router-link to="/moje-konto/pomoc">
            <img src="../assets/images/side_menu_icons/help.svg">
            <a>Pomoc</a>
          </router-link>
        </li>
      </ul>
      <div class="bottom">
        <span>Obserwuj nas</span>
        <div class="social-links">
          <a href="https://www.facebook.com/Bookmepl/" target="_blank"><img src="../assets/images/side_menu_icons/facebook.svg"></a>
          <a href="https://www.instagram.com/bookme_pl/" target="_blank"><img src="../assets/images/side_menu_icons/instagram.svg"></a>
          <a :href="'https://' + plainUrl + '/blog'" target="_blank"><img src="../assets/images/side_menu_icons/globe.svg"></a>
        </div>
        <span class="logout" @click="logout">
          <a>Wyloguj</a>
        </span>
        <div class="version">
          Bookme App 2021
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../eventBus'
import { scroller } from 'vue-scrollto/src/scrollTo'

export default {
  name: 'SideMenu',
  mounted () {
    window.addEventListener('scroll', this.setMenuType)
    this.$nextTick(function () {
      if (!window.notificationInterval) {
        window.notificationInterval = window.setInterval(() => {
          if (this.getToken()) {
            this.getNotification()
          }
        }, 30000)
      }
    })
  },
  data () {
    return {
      blackType: false,
      isPlanSet: 0,
      toPlanUrl: '/wybierz-plan',
      amount: 0,
      profileIconVisible: false,
      scroller: scroller()
    }
  },
  computed: {
    fullname: {
      get () {
        return this.$store.state.userData.fullname
      }
    },
    userImage: {
      get () {
        return this.$store.state.userData.img_url
      }
    },
    newNotification: {
      get () {
        return this.$store.state.newNotification
      },
      set (value) {
        this.$store.commit('updateNewNotification', value)
      }
    },
    showFeed: {
      get () {
        return this.$store.state.userData.show_feed
      }
    }
  },
  watch: {
    '$route' (to, from) {
      // if (to.name === 'UserProfile' || to.name === 'OtherUserProfile') {
      //   this.blackType = false
      // } else {
      //   this.blackType = true
      // }
      this.closeSideMenu()
    },
    newNotification: function (val) {
      if (this.getToken()) {
        this.getNotification()
      }
    }
  },
  async created () {
    EventBus.$on('feed-hidden', this.feedHidden)
    EventBus.$on('feed-visible', this.feedVisible)
    if (this.getToken()) {
      this.getNotification()
    }
    await this.$https('/profile', {
      method: 'GET',
      mode: 'no-cors',
      crossDomain: true
    })
      .then(res => {
        this.$store.commit('updateUserId', res.data.id)
      })
      .catch(error => {
        console.log('', error)
      })
  },
  methods: {
    checkPlan () {
      this.closeSideMenu()
      this.$https('/plans/current', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true
      })
        .then(({ data }) => {
          this.isPlanSet = data.response
          if (data.response === null) {
            this.$router.push('/wybierz-plan')
          } else {
            this.$router.push('/plan-treningowy')
          }
        })
        .catch(error => {
          console.log('', error)
        })
    },
    logout () {
      this.$router.push('/logowanie/email')
      this.deleteToken()
      if (window.notificationInterval) {
        window.clearInterval(window.notificationInterval)
        window.notificationInterval = false
      }
      if (window.messageInterval) {
        window.clearInterval(window.messageInterval)
        window.messageInterval = false
      }
      this.closeSideMenu()
    },
    setMenuType () {
      if (this.$route.name === 'UserProfile' || this.$route.name === 'OtherUserProfile') {
        this.blackType = false
      } else {
        this.blackType = true
      }
    },
    getNotification () {
      this.$https('notifications?for=all', {
        method: 'GET',
        mode: 'no-cors',
        crossDomain: true,
        headers: {
        }
      })
        .then(({ data }) => {
          this.amount = data
        })
        .catch(error => {
          console.log('', error)
        })
    },
    useBackButton () {
      if (this.$route.name !== 'UserProfile') {
        this.$router.go(-1)
      } else {
        this.closeSideMenu()
        this.scroller('#feed', 500, { cancelable: false, onStart: function () { document.body.classList.add('no-scroll') }, onDone: function () { EventBus.$emit('show-feed', false) } })
      }
    },
    feedHidden () {
      this.profileIconVisible = false
    },
    feedVisible () {
      if (this.showFeed === true) {
        this.profileIconVisible = true
      }
    },
    toggleSideMenuOpen () {
      this.$parent.sideMenuOpen = !this.$parent.sideMenuOpen
    },
    closeSideMenu () {
      this.$parent.sideMenuOpen = false
    },
    goToProfile () {
      EventBus.$emit('go-to-first-card', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  .notifications{
    position: fixed;
    color: #f1f1f1;
    z-index: 15;
    top: 0;
    right: 16%;
    width:50px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    .notification-amount {
      position: absolute;
      top: 14px;
      right: 11px;
      z-index: 1;
      width: 11px;
      height: 11px;
      object-fit: contain;
      border: solid 2px #1f1f1f;
      background-color: #ff0000;
      border-radius: 50%;
    }

    img{
      max-width: 23px;
    }
  }

  .app-menu-profile{
    transition: position .1s linear .5s, z-index .1s linear .5s, opacity linear .5s;
    position: fixed;
    top: 15px;
    right: 22px;
    width: 25px;
    height: 25px;
    display: block;
    z-index: 15;
    color: #fff;
    border-radius: 50%;
    background-size: cover;
  }
  .app-menu-profile.hidden{
    visibility: hidden;
    opacity: 0;
    transition: all linear .5s;
  }

  .app-menu-trigger{
    opacity: 1;
    visibility: visible;
  }

  .app-menu-trigger.hidden{
    transition: all ease .5s;
    visibility: hidden;
    transform:  scaleX(0);
  }

  .app-menu-back{
    transition: all .5s;
  }

  .app-menu-back.hidden{
    transition: all .5s ease;
    visibility: hidden;
    opacity: 0;
  }
</style>
