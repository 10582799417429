import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { api, getToken, saveToken, deleteToken, coverUrl, plainUrl, bookfaceUrl, getReopen, setOnboardingFinished, getOnboardingFinished, deleteOnboardingFinished } from './conf/api'
import VueAxios from 'vue-axios'
import * as VueGoogleMaps from 'vue2-google-maps'
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import '../node_modules/@fortawesome/fontawesome-free/js/all.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import { Swiper, EffectCoverflow } from 'swiper'
import 'swiper/swiper-bundle.css'
import { BootstrapVue } from 'bootstrap-vue'
import InfiniteLoading from 'vue-infinite-loading'
import VCalendar from 'v-calendar'
import 'vue-swipe-actions/dist/vue-swipe-actions.css'
import Vue2TouchEvents from 'vue2-touch-events'
import './styles/swiper.scss'
import './styles/styles.scss'
import Quagga from 'quagga'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { VueHammer } from 'vue2-hammer'
import { appUrl } from './conf/app'

Vue.use(VueHammer)
Vue.use(Quagga)
Vue.use(Vue2TouchEvents, {
  disableClick: false,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 30,
  longTapTimeInterval: 400,
  namespace: 'touch'
})
Vue.config.productionTip = false

Vue.prototype.$https = api
Vue.prototype.getToken = getToken
Vue.prototype.saveToken = saveToken
Vue.prototype.deleteToken = deleteToken
Vue.prototype.getReopen = getReopen
Vue.prototype.setOnboardingFinished = setOnboardingFinished
Vue.prototype.getOnboardingFinished = getOnboardingFinished
Vue.prototype.deleteOnboardingFinished = deleteOnboardingFinished
Vue.prototype.coverUrl = coverUrl
Vue.prototype.plainUrl = plainUrl
Vue.prototype.bookfaceUrl = bookfaceUrl
Vue.prototype.appUrl = appUrl

// api.defaults.timeout = 10000

Vue.use(VueReCaptcha, {
  siteKey: '<site key>',
  loaderOptions: {
    useRecaptchaNet: false,
    autoHideBadge: true
  }
})

api.interceptors.request.use(
  config => {
    if (getToken()) {
      // eslint-disable-next-line dot-notation
      config.headers.common['Authorization'] = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          break
        case 401:
          console.log('session expired')
          deleteToken()
          if (!getReopen()) {
            setTimeout(() => {
              router.replace({
                path: '/start',
                query: {
                  redirect: router.currentRoute.fullPath
                }
              })
            }, 100)
          } else {
            setTimeout(() => {
              router.replace({
                path: '/logowanie',
                query: {
                  redirect: router.currentRoute.fullPath
                }
              })
            }, 100)
          }
          break
        case 403:
          router.replace({
            path: '/logowanie',
            query: {
              redirect: router.currentRoute.fullPath
            }
          })
          break
        case 404:
          console.log('page not exist')
          break
        case 502:
          deleteToken()
          setTimeout(() => {
            router.replace({
              path: '/logowanie',
              query: {
                redirect: router.currentRoute.fullPath
              }
            })
          }, 100)
      }
      return Promise.reject(error.response)
    }
  }
)

Vue.use(VueAxios, axios)
Swiper.use([EffectCoverflow])
Vue.use(VueAwesomeSwiper)
Vue.use(BootstrapVue)
Vue.use(VCalendar, { componentPrefix: 'v' })
Vue.use(InfiniteLoading, {
  slots: {
    // keep default styles
    noResults: 'Brak wyników',

    // remove default styles
    noMore: 'To już wszystko'
  }
})
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraties: 'places'
  }
})

Vue.directive('ripple', {
  inserted: function (el) {
    el.addEventListener('click', function (e) {
      // Setup
      const target = el.getBoundingClientRect()
      const buttonSize = target.width > target.height ? target.width : target.height
      // remove any previous ripple containers
      const elements = document.getElementsByClassName('ripple')
      while (elements[0]) {
        elements[0].parentNode.removeChild(elements[0])
      }

      const ripple = document.createElement('span')
      ripple.setAttribute('class', 'ripple')
      el.appendChild(ripple)

      setTimeout(function () {
        ripple.style.width = buttonSize + 'px'
        ripple.style.height = buttonSize + 'px'
        // ripple.style.top = e.offsetY - 73 - buttonSize / 2 + 'px'
        ripple.style.left = -buttonSize / 5 + 'px'
        ripple.setAttribute('class', 'ripple ripple-effect')
      }, 0)
      setTimeout(function () {
        ripple.parentNode.removeChild(ripple)
      }, 500)
    }, false
    )
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
  // methods: {
  //   async recaptcha () {
  //     await this.$recaptchaLoaded()

  //     // Execute reCAPTCHA with action "login".
  //     // eslint-disable-next-line no-unused-vars
  //     const token = await this.$recaptcha('register')

  //     // Do stuff with the received token.
  //   }
  // },
  // template: '<button @click="recaptcha">Execute recaptcha</button>'
}).$mount('#app')
// new Vue({
//   router,
//   store,

//   render: h => h(App)
// }).$mount('#app')
