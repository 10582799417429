import Vue from 'vue'
import VueRouter from 'vue-router'
import { getReopen, getToken } from '../conf/api'
// import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/logowanie',
    name: 'Login',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/Login')
  },
  {
    path: '/logowanie/email',
    name: 'LoginEmail',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/LoginEmail')
  },
  {
    path: '/facebook-return',
    name: 'LoginFB',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/Login')
  },
  {
    path: '/rejestracja/regulamin',
    name: 'RegisterRules',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/RegisterRules')
  },
  {
    path: '/logowanie/przypomnij-haslo',
    name: 'RemindPassword',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/RemindPassword')
  },
  {
    path: '/rejestracja',
    name: 'Register',
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/Register')
  },
  {
    path: '/',
    name: 'UserProfile',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/UserProfile.vue')
  },
  {
    path: '/profil/:id',
    name: 'OtherUserProfile',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/OtherUserProfile')
  },
  {
    path: '/konto/stworz-profil',
    name: 'Onborading',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Onboarding.vue')
  },
  {
    path: '/konto/dodaj-zdjecie',
    name: 'OnboardingAvatar',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/OnboardingAvatar.vue')
  },
  {
    path: '/konto/uzupelnij-dane',
    name: 'OnboardingCreate',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/OnboardingCreate.vue')
  },
  {
    path: '/konto/wybierz-krag',
    name: 'OnboardingChoose',
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import('../views/OnboardingChoose')
  },
  {
    path: '/powiadomienia',
    name: 'MessegesNotification',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/MessegesNotification.vue')
  }, {
    path: '/wiadomosci/:id',
    name: 'Messages',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Messages.vue')
  },
  {
    path: '/wyszukiwanie',
    name: 'SearchTemplate',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SearchTemplate')
  },
  {
    path: '/wyszukiwanie/:param',
    name: 'SearchResultTemp',
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import('../views/SearchResultTemp')
  },
  {
    path: '/wydawnictwo/:result/',
    name: 'PublishingBookDetails',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/PublishingBookDetails')
  },
  {
    path: '/ksiegarnia/:id',
    name: 'BookstoreDetails',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/BookstoreDetails')
  },
  {
    path: '/kategorie/:id',
    name: 'TagsDetails',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TagsDetails')
  },
  {
    path: '/ksiazka/:id',
    name: 'BooksDetails',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/BooksDetails')
  },
  {
    path: '/skanuj',
    name: 'ScanBook',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/ScanBook.vue')
  },
  {
    path: '/skanuj-wynik',
    name: 'ScanResult',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/ScanResult')
  },
  {
    path: '/dodaj-ksiazke',
    name: 'AddBook',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/AddBook.vue')
  },
  {
    path: '/biblioteka',
    name: 'Library',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Library.vue')
  },
  {
    path: '/wybierz-plan',
    props: true,
    name: 'TrainingPlanChoose',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingPlanChoose.vue')
  },
  {
    path: '/edytuj-plan-stronnicowy',
    name: 'TrainingPlanPages',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingPlanPages'),
    props: true
  },
  {
    path: '/edytuj-plan-ksiazkowy',
    name: 'TrainingPlanBooks',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingPlanBooks'),
    props: true
  },
  {
    path: '/edytuj-plan-czasu',
    name: 'TrainingPlanTime',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingPlanTime'),
    props: true
  },
  {
    path: '/wybierz-ksiazki/:plan_id',
    name: 'TrainingPlanSelectBooks',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingPlanSelectBooks'),
    props: true
  },
  {
    path: '/podsumowanie-planu',
    name: 'TrainingPlanSummary',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingPlanSummary')
  },
  {
    path: '/plan-treningowy',
    name: 'TrainingPlan',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingPlan')
  },
  {
    path: '/plan-treningowy/sukces',
    name: 'TrainingPlanSuccess',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingPlanSuccess')
  },
  {
    path: '/plan-treningowy/historia',
    name: 'TrainingPlanHistory',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingPlanHistory')
  },
  {
    path: '/plan-treningowy/historia/:id',
    name: 'TrainingPlanHistoryMore',
    meta: {
      requiresAuth: true
    },
    props: true,
    component: () => import('../views/TrainingPlanHistoryMore')
  },
  {
    path: '/zaktualizuj-postepy/:id',
    props: true,
    name: 'TrainingUpdateProgress',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/TrainingUpdateProgress')
  },
  {
    path: '/dodaj-polke/:id',
    name: 'LibraryAddShelf',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/LibraryAddShelf.vue')
  },
  {
    path: '/edytuj-polke/:id',
    name: 'EditShelf',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/EditShelf.vue')
  },
  {
    path: '/recenzje',
    name: 'Review',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Review.vue')
  },
  {
    path: '/recenzje/:user_id',
    name: 'ReviewOtherUser',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/ReviewOtherUser.vue')
  },
  {
    path: '/recenzja/:book_id/:id',
    name: 'ReviewDetails',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/ReviewDetails.vue')
  },
  {
    path: '/dodaj-recenzje/:id',
    name: 'ReviewAdd',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/ReviewAdd')
  },
  {
    path: '/edytuj-recenzje/:book_id/:id',
    name: 'ReviewEdit',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/ReviewEdit')
  },
  {
    path: '/moje-konto/pomoc',
    name: 'Help',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Help.vue')
  },
  {
    path: '/moje-konto/edytuj-profil',
    name: 'SettingsEditProfile',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SettingsEditProfile.vue')
  },
  {
    path: '/pozyczone',
    name: 'BorrowLendBooks',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/BorrowLendBooks')
  },
  {
    path: '/pozycz-ksiazke/:id',
    name: 'LendBook',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/LendBook.vue')
  },
  {
    path: '/dodaj-do-polki/:id',
    name: 'BorrowBook',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/BorrowBook.vue')
  },
  {
    path: '/dodaj',
    name: 'AddPlaceholder',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/AddPlaceholder.vue')
  },
  {
    path: '/kategorie',
    name: 'Categories',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Categories')
  },
  {
    path: '/wybierz-kategorie',
    name: 'CheckCategories',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/CheckCategories.vue')
  },
  {
    path: '/moje-konto',
    name: 'Settings',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/moje-konto/regulamin',
    name: 'SettingsRules',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SettingsRules.vue')
  },
  {
    path: '/moje-konto/zasady-prywatnosci',
    name: 'SettingsPrivacyRules',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SettingsPrivacyRules.vue')
  },
  {
    path: '/moje-konto/grywalizacja',
    name: 'Gamification',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Gamification')
  },
  {
    path: '/moje-konto/ustawienia-prywatnosci',
    name: 'SettingsPrivacy',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SettingsPrivacy')
  },
  {
    path: '/moje-konto/ustawienia-powiadomien',
    name: 'SettingsNotification',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SettingsNotification')
  },
  {
    path: '/moje-konto/zablokowani',
    name: 'SettingsBlockedUsers',
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/SettingsBlockedUsers')
  },
  {
    path: '/polka/:id',
    name: 'Shelf',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Shelf')
  },
  {
    path: '/polka-szczegoly/:id',
    name: 'ShelfShowMore',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/ShelfShowMore')
  },
  {
    path: '/polec/:id',
    name: 'RecommendBook',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/RecommendBook')
  },
  {
    path: '/autor/:id',
    name: 'Authors',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Authors')
  },
  {
    path: '/nowy-bookface/:book_id',
    name: 'BookfaceCreate',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/BookfaceCreate')
  },
  {
    path: '/bookface/:bookface_id/:type',
    name: 'Bookface',
    props: true,
    meta: {
      requiresAuth: true
    },
    component: () => import('../views/Bookface')
  },
  {
    path: '/start',
    name: 'OnboardingSlider',
    props: true,
    meta: {
      requiresAuth: false
    },
    component: () => import('../views/OnboardingSlider.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition && to.name !== 'UserProfile') {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     if (store.getters.isLoggedIn) {
//       next({ path: '/' })
//     } else {
//       next()
//     }
//   }
// })
// const token = localStorage.getItem('token')

// router.beforeEach((to, from, next) => {
//   if ((!to.path.includes('/logowanie')) && !getToken()) {
//     next({ name: 'LoginEmail' })
//   } else {
//     next()
//   }
// })

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) { // <-- check for requiresAuth here
    if (!getToken()) {
      if (!getReopen()) {
        next('/start')
      } else {
        next('/logowanie')
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
