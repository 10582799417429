<template>
  <div id="app" class="top-menu-shadow top-menu-visible top-menu-slided-dark">
    <SideMenu v-if="getToken() && $route.path !== '/konto/stworz-profil' && $route.path !=='/konto/uzupelnij-dane' && $route.path !== '/konto/wybierz-krag' && $route.name !== 'BookfaceCreate' && $route.name !== 'Bookface'"/>
      <router-view></router-view>
      <router-view name="feed"></router-view>
    <BottomMenu v-if="getToken() && $route.path !== '/konto/dodaj-zdjecie' && $route.path !=='/konto/uzupelnij-dane' && $route.path !== '/konto/wybierz-krag' && $route.path !== '/konto/stworz-profil' && $route.name !== 'BookfaceCreate' && $route.name !== 'Bookface'"/>
  </div>
</template>
<script>
import SideMenu from './components/SideMenu'
import BottomMenu from './components/BottomMenu'

export default {
  name: 'App',
  components: {
    SideMenu,
    BottomMenu
  },
  data () {
    return {
      sideMenuOpen: false
    }
  },
  created () {

  },
  mounted () {

  }
}
</script>
<style lang="scss">

</style>
