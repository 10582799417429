import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sessionTime: '',
    status: '',
    // token: localStorage.getItem('token') || '',
    user: {},
    // my_id: 442,
    newNotification: 0,
    serchingParam: '',
    searchingRequestResult: [],
    isResultEmpty: false,
    addBookSelectedGenres: [],
    addBook: {
      title: '',
      author: '',
      publisher: '',
      published_year: '',
      pages: '',
      isbn: '',
      description: ''
    },
    trainingPlan: {
      trainingType: '',
      trainingPlanTitle: '',
      pagesPerSession: '',
      pagesTimeType: '',
      daysWithTrainer: '',
      timeWithTrainer: '',
      hoursPerSession: '',
      minPerSession: '',
      timePerSession: '',
      booksPerSession: '',
      booksTimeType: '',
      booksInTraining: []
    },
    userData: {
      userId: '',
      name: '',
      surname: '',
      fullname: '',
      motto: '',
      city: '',
      categories: [],
      email: '',
      password: '',
      img_url: '',
      thumb_url: require('../assets/avatar-placeholder.jpg'),
      gem_lvl: '',
      books_read: '',
      books_readed: '',
      books_total: '',
      current_read: '',
      current_plan: '',
      lend_books: '',
      notify_comments: '',
      notify_library: '',
      notify_likes: '',
      notify_message: '',
      pages: '',
      read_time: '',
      points: '',
      public_profile: '',
      public_rivalry: '',
      shared_books: '',
      shelf_read: '',
      show_library: '',
      is_bookshop: '',
      reviews: '',
      one_signal_id: '',
      show_feed: ''
    },
    otherUser: {
      userData: [],
      userGam: [],
      isFriend: ''
    },
    searchBooks: {
      id: 0,
      name: '',
      items: []
    }
  },
  mutations: {
    updateNewNotification (state, payload) {
      state.newNotification = payload
    },
    updateSearchingParam (state, payload) {
      state.searchingParam = payload
    },
    updateSessionTime (state, payload) {
      state.sessionTime = payload
    },
    // user data
    updateUserId (state, payload) {
      state.userData.userId = payload
    },
    updateUserMotto (state, payload) {
      state.userData.motto = payload
    },
    updateUserFullname (state, payload) {
      state.userData.fullname = payload
    },
    updateUserName (state, payload) {
      state.userData.name = payload
    },
    updateUserSurname (state, payload) {
      state.userData.surname = payload
    },
    updateUserCity (state, payload) {
      state.userData.city = payload
    },
    updateUserPassword (state, payload) {
      state.userData.password = payload
    },
    updateUserEmail (state, payload) {
      state.userData.email = payload
    },
    updateUserImg (state, payload) {
      state.userData.img_url = payload
    },
    updateUserThumb (state, payload) {
      state.userData.thumb_url = payload
    },
    updateUserCurrentPlan (state, payload) {
      state.userData.current_plan = payload
    },
    updateUserCurrentRead (state, payload) {
      state.userData.current_read = payload
    },
    updateUserBooksRead (state, payload) {
      state.userData.books_read = payload
    },
    updateUserBooksReaded (state, payload) {
      state.userData.books_readed = payload
    },
    updateUserBooksTotal (state, payload) {
      state.userData.books_total = payload
    },
    updateUserLendBooks (state, payload) {
      state.userData.lend_books = payload
    },
    updateUserNotifyComments (state, payload) {
      state.userData.notify_comments = payload
    },
    updateUserNotifyLibrary (state, payload) {
      state.userData.notify_library = payload
    },
    updateUserNotifyLikes (state, payload) {
      state.userData.notify_likes = payload
    },
    updateUserNotifyMessage (state, payload) {
      state.userData.notify_message = payload
    },
    updateUserPages (state, payload) {
      state.userData.pages = payload
    },
    updateUserReadTime (state, payload) {
      state.userData.read_time = payload
    },
    updateUserPoints (state, payload) {
      state.userData.points = payload
    },
    updateUserPublicProfile (state, payload) {
      state.userData.public_profile = payload
    },
    updateUserPublicRivalry (state, payload) {
      state.userData.public_rivalry = payload
    },
    updateUserSharedBooks (state, payload) {
      state.userData.shared_books = payload
    },
    updateUserShelfRead (state, payload) {
      state.userData.shelf_read = payload
    },
    updateUserShowLibrary (state, payload) {
      state.userData.show_library = payload
    },
    updateUserIsBookshop (state, payload) {
      state.userData.is_bookshop = payload
    },
    updateUserGemLvl (state, payload) {
      state.userData.gem_lvl = payload
    },
    updateUserReviews (state, payload) {
      state.userData.reviews = payload
    },
    updateUserOneSignalId (state, payload) {
      state.userData.one_signal_id = payload
    },
    updateUserShowFeed (state, payload) {
      state.userData.show_feed = payload
    },
    // searching result
    updateSearchingResult (state, payload) {
      state.searchingRequestResult = payload
    },
    updateIsResultEmpty (state, payload) {
      state.isResultEmpty = payload
    },
    // addBook
    updateAddBookTitle (state, payload) {
      state.addBook.title = payload
    },
    updateAddBookAuthor (state, payload) {
      state.addBook.author = payload
    },
    updateAddBookPublisher (state, payload) {
      state.addBook.publisher = payload
    },
    updateAddBookPublishedYear (state, payload) {
      state.addBook.published_year = payload
    },
    updateAddBookPages (state, payload) {
      state.addBook.pages = payload
    },
    updateAddBookISBN (state, payload) {
      state.addBook.isbn = payload
    },
    updateAddBookDescription (state, payload) {
      state.addBook.description = payload
    },
    updateAddBookSelectedGenres (state, payload) {
      state.addBookSelectedGenres = payload
    },
    // otherUser
    updateOtherUserData (state, payload) {
      state.otherUser.userData = payload
    },
    updateOtherUserGam (state, payload) {
      state.otherUser.userGam = payload
    },
    updateIsFriend (state, payload) {
      state.otherUser.isFriend = payload
    },
    // training plan
    updateTrainingPlanTitle (state, payload) {
      state.trainingPlan.trainingPlanTitle = payload
    },
    updatePagesPerSession (state, payload) {
      state.trainingPlan.pagesPerSession = payload
    },
    updatePagesTimeType (state, payload) {
      state.trainingPlan.pagesTimeType = payload
    },
    updateDaysWithTrainer (state, payload) {
      state.trainingPlan.daysWithTrainer = payload
    },
    updateTimeWithTrainer (state, payload) {
      state.trainingPlan.timeWithTrainer = payload
    },
    updateHoursPerSession (state, payload) {
      state.trainingPlan.hoursPerSession = payload
    },
    updateMinPerSession (state, payload) {
      state.trainingPlan.minPerSession = payload
    },
    updateTimePerSession (state, payload) {
      state.trainingPlan.timePerSession = payload
    },
    updateBooksPerSession (state, payload) {
      state.trainingPlan.booksPerSession = payload
    },
    updateBooksTimeType (state, payload) {
      state.trainingPlan.booksTimeType = payload
    }
    // searchbook
    // updateBookId (state, paylod) {

    // }
  },
  actions: {
  },
  modules: {
  }
})
